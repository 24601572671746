/*eslint-disable*/
import React from "react";
import propTypes from "prop-types";

const Login = (props) => (
    <nav className="login">
        <h2>Inventory Login</h2>
        <p>Sign in to manage your store&apos;s inventory.</p>
        <button className="github" onClick={() => props.authenticate("Github")}>Log In With Github</button>
    </nav>
);

Login.propTypes = {
    authenticate: propTypes.func.isRequired
};

export default Login;