import React from "react";
import propTypes from "prop-types";

class EditFishForm extends React.Component {
    static propTypes = {
        fish: propTypes.shape({
            name: propTypes.string,
            price: propTypes.number,
            status: propTypes.string,
            description: propTypes.string,
            image: propTypes.string,
        }),
        updateFish: propTypes.func,
        index: propTypes.string,
        deleteFish: propTypes.func
    }
    handleChange = (e) => {
        console.log(e.currentTarget.value);
        // Update that Fish
        // 1. take a copy of the current fish
        const updatedFish = { 
            ...this.props.fish,
            [e.currentTarget.name]: e.currentTarget.value 
        };
        this.props.updateFish(this.props.index, updatedFish);
    }
    render() {
        return (
            <div className="fish-edit">
                <input type="text" name="name" onChange={this.handleChange} value={this.props.fish.name} />
                <input type="text" name="price" onChange={this.handleChange} value={this.props.fish.price} />
                <select type="text" name="status" onChange={this.handleChange} value={this.props.fish.status}>
                    <option value="available">Fresh!</option>
					<option value="unavailable">Sold Out!</option>
                </select>
                <textarea name="description" onChange={this.handleChange} value={this.props.fish.description}></textarea>
                <input type="text" name="image" onChange={this.handleChange} value={this.props.fish.image} />
                <button onClick={() => this.props.deleteFish(this.props.index)}>Remove Fish</button>
            </div>
        );
    }
}

export default EditFishForm;