import Rebase from "re-base";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyCFno1ioj2s3jQJWR20QUl2qihDU8uq8kk",
    authDomain: "catch-of-the-day-allyce-amidon.firebaseapp.com",
    databaseURL: "https://catch-of-the-day-allyce-amidon-default-rtdb.firebaseio.com"
});

const base = Rebase.createClass(firebaseApp.database());

// this is a named export
export { firebaseApp };

// this is a default export
export default base;